<template>
  <div>
    <div class="profile-title">
      <span
        >Scripts
        <hr />
        {{ plays.length | convertToThousand }}</span
      >
    </div>
    <form class="search">
      <div class="input-wrapper">
        <svg viewBox="0 0 50 50">
          <path
            d="M21 3C11.601563 3 4 10.601563 4 20C4 29.398438 11.601563 37 21 37C24.355469 37 27.460938 36.015625 30.09375 34.34375L42.375 46.625L46.625 42.375L34.5 30.28125C36.679688 27.421875 38 23.878906 38 20C38 10.601563 30.398438 3 21 3 Z M 21 7C28.199219 7 34 12.800781 34 20C34 27.199219 28.199219 33 21 33C13.800781 33 8 27.199219 8 20C8 12.800781 13.800781 7 21 7Z"
          />
        </svg>
        <input type="search" name="" id="" placeholder="Search for plays" />
      </div>
      <button type="submit" class="btn">Search</button>
    </form>

    <ul class="filters">
      <li
        v-for="filter in filters"
        :key="filter"
        class="filter"
        :class="{ active: filter === activeFilter }"
        @click="activeFilter = filter"
      >
        {{ filter }}
      </li>
    </ul>

    <transition-group name="list-complete" class="play-grid" tag="div">
      <template v-if="!plays">
        <LoadingCard v-for="n in 8" :key="n" width="100%" height="118px" />
      </template>
      <template v-else>
        <AdminPlay
          v-for="play in filteredPlays"
          :key="play.id"
          :play="play"
          :maxViews="maxViews"
        ></AdminPlay>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { AdminPlay, LoadingCard } from "@/components";
import { scriptCollections, auth } from "@/firebase-config";

export default {
  name: "ManagePlays",
  data() {
    return {
      plays: [],
      activeFilter: "All",
      filters: ["All", "TV", "Film", "Unpurchased", "Purchased", "Most Views"],
    };
  },
  components: {
    AdminPlay,
    LoadingCard,
  },
  computed: {
    filteredPlays() {
      if (this.activeFilter === "All") return this.plays;
      if (this.activeFilter === "Most Views") {
        const sortedPlays = [...this.plays].sort((a, b) => b.views - a.views);
        return sortedPlays;
      }
      if (this.activeFilter === "Purchased") {
        return this.plays.filter((play) => play.owner !== null);
      }
      if (this.activeFilter === "Unpurchased") {
        return this.plays.filter((play) => play.owner === null);
      }
      return this.plays.filter(
        (play) =>
          play.media_type.toLowerCase() === this.activeFilter.toLowerCase()
      );
    },
    maxViews() {
      const sortedPlays = [...this.plays].sort((a, b) => b.views - a.views);
      return sortedPlays[0].views;
    },
  },
  methods: {
    async fetchPlays() {
      try {
        this.loading = true;
        await scriptCollections.onSnapshot(
          (querySnapshot) => {
            let play = querySnapshot.docChanges()[0].doc.data();
            play.id = querySnapshot.docChanges()[0].doc.id;
            if (querySnapshot.docChanges()[0].type == "modified") {
              this.modifyPlay(play, "modify");
            } else if (querySnapshot.docChanges()[0].type == "removed") {
              this.modifyPlay(play, "remove");
            } else {
              querySnapshot.docs.forEach((doc) => {
                let data = doc.data();
                if (doc.id !== auth.currentUser.uid) {
                  data.id = doc.id;
                  this.plays.push(data);
                }
              });
            }
          },
          function (error) {
            throw error;
          }
        );
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
    modifyPlay(data, action) {
      const playIndexToModify = this.plays.findIndex(
        (play) => play.id === data.id
      );
      if (playIndexToModify < 0) return;

      switch (action) {
        case "modify":
          this.$set(this.plays, playIndexToModify, data);
          break;

        case "remove":
          this.plays.splice(playIndexToModify, 1);
          break;
      }
    },
  },
  mounted() {
    this.fetchPlays();
  },
};
</script>

<style lang="scss" scoped>
.profile-title {
  margin-top: 0;

  span {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: repeat(3, auto);

    hr {
      width: 20px;
    }
  }
}

.search {
  padding: 10px 20px;
  background: $input-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* flex-flow: wrap; */
  justify-content: space-between;

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    svg {
      height: 20px;
      fill: $dark-grey;
    }

    input {
      color: $dark-grey;
      margin-left: 15px;
      margin-right: 15px;
      width: 100%;
    }
  }

  .btn {
    padding: 10px 20px;
    background: whitesmoke;
    border-radius: 100px;
    font-weight: 600;
    transition: 0.2s background ease;

    &:hover {
      background: $dark-grey;
    }
  }
}

.filters {
  display: flex;
  list-style: none;
  margin-top: 20px;
  font-size: 1.4rem;
  color: $light-grey;
  font-weight: 300;
  width: 100%;
  max-width: 92vw;
  overflow-x: auto;
  scrollbar-width: none;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }

  .filter {
    transition: 0.2s all ease;
    cursor: pointer;
    border-bottom: 1px solid grey;
    padding: 10px;
    text-align: center;
    flex-grow: 1;
    min-width: fit-content;

    &.active {
      color: white;
      font-weight: 500;
      border-bottom-color: white;
    }

    &:hover {
      color: $dark-grey;
    }
  }
}

.play-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-top: 30px;
  /* grid-auto-flow: dense; */
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: scale(0);
}

.list-complete-leave {
  transition-delay: 0.3s;
}
</style>
